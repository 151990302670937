








































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { PageNum, School } from "@/tool/interface-index";
import { ExamStudent } from "@/tool/_class";
import { deleteExamStudent, getExamStudentList } from "@/api/examStudent";
import { getSchoolCascadeList, getSchoolList } from "@/api/school";
import { getExamProjectList } from "@/api/examProject";
import { ExamProject } from "@/tool/interface-index";
import * as _ from "lodash";
import { exportJson2Excel } from "@/utils/excel";
import { formatJson } from "@/utils";
import BuildExamAnswerPackageDialog from "./build-exam-answer-package-dialog.vue";

@Component({
  name: "ExamStudentListPage",
  components: {
    cardBox,
    pagination,
    BuildExamAnswerPackageDialog,
  },
})
export default class extends Vue {
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private tableData: Array<object> = [];
  private examProjectList: ExamProject[] = [];
  private query: {
    keyWords: string;
    cascadeExamSchoolId: string[];
    examProjectId: string;
  } = {
    cascadeExamSchoolId: [],
    keyWords: "",
    examProjectId: "",
    //examState: string
  };

  private examStateOptions: any[] = [];

  private schools: any[] = [];

  private queryStudents() {
    this.getExamStudentListData();
  }

  private deleteData(examStudentId: string) {
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteExamStudent(examStudentId).then((res: any) => {
          if (res.code == 0) {
            this.getExamStudentListData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        });
      })
      .catch(() => {});
  }

  private loading: boolean = false;
  private async getExamStudentListData() {
    try {
      this.loading = true;
      const res = await getExamStudentList({
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage,
        keyWords: this.query.keyWords,
        examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
        examProjectId: this.query.examProjectId || "必选参数",
        examType: "formalExamMode",
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }
  }
  private upDataPage() {
    this.getExamStudentListData();
  }

  private exportExamStudent(
    index: number,
    examStudents: any[],
    fileName: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const tHeader = [
        "考号",
        "姓名",
        "性别",
        "学校",
        "班级",
        "考点名称",
        "相片",
        "座位号"
      ];
      const filterVal = [
        "examAccount",
        "examStudentName",
        "sex",
        "schoolName",
        "className",
        "examSchoolName",
        "photoFileName",
        "str01"
      ];
      const data = formatJson(filterVal, examStudents);
      setTimeout(() => {
        exportJson2Excel(tHeader, data, fileName);
        resolve();
      }, index * 300);
    });
  }

  private exportLoading: boolean = false;
  private async doExportExamStudentClick() {
    try {
      this.exportLoading = true;
      //
      let examProjectName: string = "";
      const idx = this.examProjectList.findIndex((examProject) => {
        return (
          examProject.examProjectId == (this.query.examProjectId || "必选参数")
        );
      });
      if (idx >= 0) {
        examProjectName = this.examProjectList[idx].examProjectName;
      }
      //
      let p: any[] = [];
      let students: any[] = [];
      let examSchools: any[] = [];
      const resp = await getSchoolList({ curPage: 1, pageSize: 999 });
      examSchools.push(...resp.data.items);
      //
      const resp1 = await getExamStudentList({
        pageSize: this.pageNum.pageSize,
        curPage: 1,
        keyWords: this.query.keyWords,
        examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
        examProjectId: this.query.examProjectId || "必选参数",
        examType: "formalExamMode",
      });
      students.push(...resp1.data.items);
      //
      for (let i = 2; i <= resp1.data.totalPage; i++) {
        p.push(
          getExamStudentList({
            pageSize: this.pageNum.pageSize,
            curPage: i,
            keyWords: this.query.keyWords,
            examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
            examProjectId: this.query.examProjectId || "必选参数",
            examType: "formalExamMode",
          })
        );
      }
      const resps = await Promise.all(p);
      for (let i = 0; i < resps.length; i++) {
        students.push(...resps[i].data.items);
      }
      //
      let p1: any[] = [];
      let x: number = 0;
      for (let i = 0; i < examSchools.length; i++) {
        const filterStudents: any[] = students
          .filter((student) => {
            return student.examSchoolId == examSchools[i].schoolId;
          })
          .map((student) => {
            return _.merge(student, {
              examSchoolName: examSchools[i].schoolName,
            });
          });
        if (filterStudents.length > 0) {
          x++;
          console.log(
            `export ${x} ${examSchools[i].schoolName}: ${filterStudents.length}人`
          );
          p1.push(
            this.exportExamStudent(
              x,
              filterStudents,
              `${examProjectName}_${x}_${examSchools[i].schoolName}`
            )
          );
        }
      }
      await Promise.all(p1);
      this.$message({
        type: "success",
        message: `导出完成，共${x}个文件，请核查是否有缺失。`,
      });
    } catch (error: any) {
      this.$message({
        type: "error",
        message: `导出失败，${error.message || error.msg || ""}。`,
      });
    } finally {
      this.exportLoading = false;
    }
  }

  private doNewExamStudentClick() {
    this.$router.push({
      path: "newExamStudent",
      query: {
        examProjectId: this.query.examProjectId,
        cascadeExamSchoolId: this.query.cascadeExamSchoolId,
      },
    });
  }

  private doBuildExamAnswerPackageClick(examStudent: ExamStudent) {
    (
      this.$refs.buildExamAnswerPackageDialog as BuildExamAnswerPackageDialog
    ).show(examStudent);
  }

  private doEditExamStudentClick(examStudentId: string) {
    this.$router.push({
      path: "editExamStudent",
      query: {
        examStudentId,
      },
    });
  }

  private getCascadeItem(keyId: string, cascades: any[]): any {
    let result = undefined;
    cascades.some(function iter(obj) {
      if (obj.keyId == keyId) {
        result = obj;
        return true;
      }
      return Array.isArray(obj.children) && obj.children.some(iter);
    });
    return result;
    /*
    for (let i = 0; i < cascades.length; i++) {
      if (cascades[i].keyId == keyId) {
        return cascades[i]
      }
      if (_.has(cascades[i], 'children')) {
        return this.getCascadeItem(keyId, cascades[i].children);
      }

    }
    */
  }

  private getCascadeSchoolId(schoolId: string): string[] {
    let arr: string[] = [];
    let keyId: string = schoolId;
    do {
      const item = this.getCascadeItem(keyId, this.schools);
      if (item) {
        arr.push(keyId);
        keyId = item.parentKeyId;

        continue;
      }
      break;
    } while (true);
    return arr.reverse();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  private __init() {
    getSchoolCascadeList().then(({ data }) => {
      this.schools = data.items;
      this.removeNullChildren(this.schools);
    });
    getExamProjectList({
      examType: "formalExamMode",
      pageSize: 999,
      curPage: 1,
    }).then((res) => {
      this.examProjectList = res.data.items;
    });
  }
  mounted() {
    //this.getExamStudentListData();
    this.__init();
  }
  activated() {
    this.getExamStudentListData();
  }
}
